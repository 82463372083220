import React, {useEffect, useState} from "react";
import Select from "react-select";
import api from "../../services/api";
import * as utils from "../../utils/utils.js";

import Extension from '../../components/Extension'

export default function Assinador() {

    // ESTADOS ONDE AS VARIÁVIES E VALORES DOS INPUTS FICAM ARMAZENADAS
    const [certificadosInstalados, setCertificadosInstalados] = useState("");
    const [certificadoSelecionado, setCertificadoSelecionado] = useState("");
    const [tipoAssinante, setTipoAssinante] = useState("Representantes");
    const [tipoDocumento, setTipoDocumento] = useState("XMLDocumentacaoAcademica")
    const [assinando, setAssinando] = useState(false)
    const [documento, setDocumento] = useState(null);

    // ESTADOS ONDE AS VARIÁVEIS DE COPIA FICAM ARMAZENADAS
    const [docAcademica, setDocumentoAcademica] = useState(null);
    const [docDiplomado, setDocumentoDiplomado] = useState(null);
    const [loadingCopia, setLoadingCopia] = useState(false);

    // VERIFICA SE A EXTENSÃO DA BRY ESTÁ INSTALADA
    const extensaoInstalada = utils.isExtensionInstalled();

    // MATRICULA DO ALUNO
    //matricula = "";

    // USANDO A EXTENSÃO, VERIFICA OS CERTIFICADOS INSTALADOS E ARMAZENA NO ESTADO DA APLICAÇÃO
    useEffect(() => {
        if (extensaoInstalada) {
            window.BryExtension.listCertificates().then(certificados => {
                certificados.forEach(certificado => {
                    certificado.label = certificado.name;
                });
                setCertificadosInstalados(certificados);
            });
        }
    }, [extensaoInstalada]);

    // FUNÇÃO QUE SERÁ EXECUTADA AO ENVIAR O FORMULÁRIO
    function enviarFormulario(event) {
        event.preventDefault();
        setAssinando(true);
        assinar();
    }

    // FUNÇÃO QUE SERÁ CHAMADA PELO FORMULÁRIO PARA ASSINAR O DOCUMENTO
    async function assinar() {

        console.log(documento);
        console.log(tipoAssinante);
        console.log(certificadoSelecionado.certificateData);

        //matricula = documento.name.replace("Documentacao_","").replace(".xml","");
        // CRIA UM FORMDATA QUE SERÁ ENVIADO PARA O BACKEND
        const dados = new FormData();
        dados.append('documento', documento);
        dados.append('tipoAssinante', tipoAssinante);
        dados.append('certificate', certificadoSelecionado.certificateData);

        let tipo;

        if (tipoDocumento === 'XMLDocumentacaoAcademica104') {
            tipo = 'XMLDocumentacaoAcademica';
        } else if (tipoDocumento === 'XMLDocumentacaoAcademica105') {
            tipo = 'XMLDocumentacaoAcademica105'
        } else {
            tipo = 'XMLDocumentacaoGeral105'
        }

        await api.post(`/${tipo}/inicializa`, dados, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then(res => {
                assinarExtensao(res.data, certificadoSelecionado.certificateData);

            })
            .catch(function (error) {
                if (error.response) { // get response with a status code not in range 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) { // no response
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
        setAssinando(false);
    }

    async function assinarExtensao(respostaInicializacao, certificado) {

        console.log(respostaInicializacao);

        const inputExtension = {};

        // CONFIGURA O O OBJETO QUE RETORNOU DA INICIALIZAÇÃO DA MANEIRA QUE A EXTENSÃO RECEBE
        inputExtension.formatoDadosEntrada = 'Base64'
        inputExtension.formatoDadosSaida = 'Base64'
        inputExtension.algoritmoHash = 'SHA256'
        inputExtension.assinaturas = [{}];
        inputExtension.assinaturas[0].hashes = respostaInicializacao.signedAttributes[0].content;
        inputExtension.assinaturas[0].nonce = respostaInicializacao.signedAttributes[0].nonce;

        inputExtension.assinaturas.forEach(
            assinatura => (assinatura.hashes = [assinatura.hashes])
        );

        console.log(inputExtension);

        // USA A EXTENSÃO PARA CIFRAR OS DADOS
        await window.BryExtension.sign(
            certificadoSelecionado.certId,
            JSON.stringify(inputExtension)
        ).then(async assinatura => {

            console.log("assinatura")
            console.log(assinatura)

            // SE OS DADOS FORAM CIFRADOS, CRIA UM FORMDATA QUE SERÁ ENVIADO PARA A FINALIZAÇÃO
            const dados = new FormData();
            dados.append('initializedDocuments', respostaInicializacao.initializedDocuments[0].content);
            dados.append('certificate', certificado);
            dados.append('cifrado', assinatura.assinaturas[0].hashes[0]);
            dados.append('tipoAssinante', tipoAssinante);
            dados.append('documento', documento);

            // FAZ A REQUISIÇÃO DE FINALIZAÇÃO PARA O BACKEND
            try {

                let tipo;

                if (tipoDocumento === 'XMLDocumentacaoAcademica104') {
                    tipo = 'XMLDocumentacaoAcademica';
                } else {
                    tipo = 'XMLDocumentacaoAcademica105'
                }

                const respostaRequisicao = await api.post(`/${tipo}/finaliza`, dados);

                setAssinando(false);
                console.log("response", respostaRequisicao);
                if (respostaRequisicao.data.message) {
                    // SE TEM ALGUMA MENSAGEM DE ERRO, MOSTRA A MENSAGEM
                    alert(respostaRequisicao.data.message)
                } else {
                    // REALIZA O DOWNLOAD DO DIPLOMA ASSINADO
                    //console.log(respostaRequisicao.data);
                    const element = document.createElement("a");
                    const file = new Blob([respostaRequisicao.data], {type: "text/xml"});
                    element.href = URL.createObjectURL(file);
                    element.download = "Documentacao_Assinada_" + documento.name.replace("Documentacao_", "").replace("Assinada_", "").replace("_Assinada_IESEmissora_", "").replace("_Assinada_Representantes", "").replace(".xml", "") + "_" + tipoAssinante + ".xml";
                    document.body.appendChild(element);
                    element.click();
                    alert("Documento assinado com sucesso!");
                    //window.location.href = respostaRequisicao.data.documentos[0].links[0].href;
                }
            } catch (err) {
                alert(err);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    // FUNÇÃO QUE SERÁ EXECUTADA AO ENVIAR O FORMULÁRIO DE COPIA
    async function handleCopy(event) {
        // Evita que a página atualize ao dar um submit
        event.preventDefault();

        setLoadingCopia(true);

        const dados = new FormData();
        dados.append('documentacaoAcademica', docAcademica);
        dados.append('XMLDiplomado', docDiplomado);

        try {
            // REALIZA REQUISIÇÃO PARA O BACKEND
            const response = await api.post(api.baseURL + `XMLDiplomado/copia-nodo`, dados);
            console.log("response", response);
            if (response.data.message) {
                alert(response.data.message)
            } else {
                // REALIZA O DOWNLOAD DO DIPLOMA COM A COPIA
                const element = document.createElement("a");
                const file = new Blob([response.data], {type: "text/xml"});
                element.href = URL.createObjectURL(file);
                element.download = "Diplomado-Gerado" + docAcademica.name.replace("Documentacao_Assinada", "").replace("_Representantes_IESEmissoraDadosDiploma_IESEmissoraRegistro", "");
                document.body.appendChild(element);
                element.click();
                alert("Arquivo gerado com sucesso!");
            }

        } catch (err) {
            // DA UM ALERT COM A MENSAGEM DE ERRO
            alert(err);
        }

        setLoadingCopia(false);
    }

    // HTML DO FRONTEND
    return (
        <>
            {extensaoInstalada ? (
                <div style={{display: "flex"}}>
                    <form onSubmit={enviarFormulario}>
                        <h2>Assinador de Diploma Digital</h2>
                        <label htmlFor="certificate">Selecione o certificado A3 que será utilizado para assinar*</label>
                        <Select
                            id="certificate"
                            options={certificadosInstalados}
                            onChange={event => setCertificadoSelecionado(event)}
                            value={certificadoSelecionado}
                            required
                        />

                        {certificadoSelecionado ? (
                            <React.Fragment>
                                <input
                                    type="text"
                                    readOnly
                                    value={certificadoSelecionado.issuer || ""}
                                />
                                <input
                                    type="text"
                                    readOnly
                                    value={certificadoSelecionado.expirationDate || ""}
                                />
                                <input
                                    type="text"
                                    readOnly
                                    value={certificadoSelecionado.certificateType || ""}
                                />
                            </React.Fragment>
                        ) : (
                            ""
                        )}

                        <label htmlFor="docLabel">Documento a ser assinado</label>
                        <label htmlFor="documento" className="fileUp">
                            {documento ? (
                                <React.Fragment>{documento.name}</React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <i className="fa fa-upload"></i>
                                    Selecione o arquivo
                                </React.Fragment>
                            )}
                            <input
                                id="documento"
                                type="file"
                                onChange={event => setDocumento(event.target.files[0])}
                            />
                        </label>

                        <label htmlFor="tipoDocumento">Tipo de Documento*</label>
                        <select
                            name="tipoDocumento"
                            value={tipoDocumento}
                            onChange={event => {
                                setTipoDocumento(event.target.value);
                                setTipoAssinante("Representantes")
                            }}
                            required
                        >
                            <option value="XMLArquivoFiscalizacao">XML Arquivo Fiscalização</option>
                            <option value="XMLCurriculoEscolar">XML Curriculo Escolar</option>
                            <option value="XMLDiplomasAnulados">XML Diplomas Anulados</option>
                            <option value="XMLDiplomaDigital">XML Diploma Digital</option>
                            <option value="XMLDocumentacaoAcademica104">XML Documentação Acadêmica - 1.04</option>
                            <option value="XMLDocumentacaoAcademica105">XML Documentação Acadêmica - 1.05</option>
                            <option value="XMLHistoricoEscolar">XML Histórico Escolar</option>
                        </select>

                        <label htmlFor="tipoAssinante">Tipo de Assinante*</label>
                        <select
                            name="tipoAssinante"
                            value={tipoAssinante}
                            onChange={event => setTipoAssinante(event.target.value)}
                            required
                        >
                            <option value="Representantes">Representantes</option>
                            {tipoDocumento === "XMLDiplomado" ?
                                (
                                    <>
                                        <option value="IESRegistradora">Registradora da IES</option>
                                    </>
                                ) :
                                (
                                    <>
                                        <option value="IESEmissoraDadosDiploma">IES Emissora em Dados Diploma</option>
                                        <option value="IESEmissoraRegistro">IES Emissora para registro</option>
                                    </>
                                )
                            }
                        </select>

                        <button className="btn" type="submit">
                            Assinar
                        </button>

                        <label>
                            {assinando ? "Realizando a assinatura do documento..." : ""}
                        </label>
                    </form>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <form onSubmit={handleCopy}>
                        <h2>Gerar o XML do Diplomado</h2>
                        <link
                            rel="stylesheet"
                            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
                        />

                        <label htmlFor="docLabel">Documentação Acadêmica</label>
                        <label htmlFor="docAcademica" className="fileUp">
                            {docAcademica ? (
                                <React.Fragment>{"   " + docAcademica.name.substr(0, 31) + "..." + docAcademica.name.substr(-5) + "   "}</React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <i className="fa fa-upload"></i>
                                    Selecione o arquivo
                                </React.Fragment>
                            )}
                            <input
                                id="docAcademica"
                                type="file"
                                onChange={event => setDocumentoAcademica(event.target.files[0])}
                            />
                        </label>

                        <label htmlFor="docLabel">Documentação Diplomado</label>
                        <label htmlFor="docDiplomado" className="fileUp">
                            {docDiplomado ? (
                                <React.Fragment>{docDiplomado.name}</React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <i className="fa fa-upload"></i>
                                    Selecione o arquivo
                                </React.Fragment>
                            )}
                            <input
                                id="docDiplomado"
                                type="file"
                                onChange={event => setDocumentoDiplomado(event.target.files[0])}
                            />
                        </label>

                        <button className="btn" type="submit">
                            Gerar XML Diplomado
                        </button>

                        <label htmlFor="instrucoes"><br/><h3>Ordem de Assinaturas:</h3>
                            <br/>1 - Assinatura da Secretária Acadêmica
                            <br/>2 - Assinatura do Diretor Acadêmico
                            <br/>3 - Assinatura da IES Emissora
                            <br/>4 - Geração do XML do Diplomado
                        </label>


                        <div>{loadingCopia ? "Realizando a geração do XML do Diplomado ..." : ""}</div>
                    </form>
                </div>
                // SE A EXTENSÃO NÃO ESTIVAR INSTALADA, RENDERIZA O COMPONENTE DE MANUAL DE INSTALAÇÃO
            ) : (<Extension/>)}
        </>
    );
}
