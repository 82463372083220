export function detectBrowser() {
  if (isChrome()) {
    return "chrome";
  } else if (isFirefox()) {
    return "firefox";
  } else if (isEdge()) {
    return "edge";
  } else if (isOpera()) {
    return "opera";
  } else if (isSafari()) {
    return "safari";
  } else if (isIE()) {
    return "iE";
  } else {
    return "unknown";
  }
}

/**
 * Funções simplificadas para detecção do browser, modifique se achar necessário
 */
function isOpera() {
  return !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;
}

function isFirefox() {
  return typeof InstallTrigger !== "undefined";
}

function isSafari() {
  return navigator.userAgent.indexOf("Safari") > -1;
}

function isIE() {
  return /*@cc_on!@*/ false || !!document.documentMode;
}

function isEdge() {
  if (document.documentMode || /Edge/.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
}

function isChrome() {
  return /Google Inc/.test(navigator.vendor);
}

export function isExtensionInstalled() {
  if (
    typeof window.BryExtension !== "undefined" &&
    typeof window.BryExtension.listCertificates === "function"
  )
    return true;
  else return false;
}

export function validaCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF === "00000000000") return false;

    for (let i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }