import React from 'react';
import * as utils from "../../utils/utils.js";

export default function Extension() {

const browser = utils.detectBrowser();

return (
    <div className="container">
          {browser === "chrome" ? (
            <div className="isChrome">
              <h3 className="extension-message">
                Detectamos que a Extensão para Assinatura Digital não está
                instalada.
              </h3>
              <p>Segue abaixo um passo a passo para instalação da extensão</p>
              <p>
                <strong>1º Passo -</strong> Clique no botão abaixo para acessar
                a Extensão no Chrome WebStore
              </p>
              <a
                href="https://chrome.google.com/webstore/detail/dhikfimimcjpoaliefjlffaebdeomeni"
                className="btn btn-lg btn-primary btn-extension-install"
              >
                Instalar Extensão via Chrome WebStore!
              </a>

              <p>
                <strong>2º Passo -</strong> Clique no botão{" "}
                <strong>USAR NO CHROME</strong>
              </p>
              <img
                alt="Print use on chrome button"
                src={require("../../assets/imgs/use_on_chrome_button.jpg")}
              />
              <p>
                <br />
                <br />
                <strong>3º Passo -</strong> Você deve retornar para esta página
                que ela será atualizada.
              </p>
            </div>
          ) : (
            ""
          )}
          {browser === "firefox" ? (
            <div className="isFirefox">
              <h3 className="extension-message">
                Detectamos que a extensão para Assinatura Digital não está
                instalada.
              </h3>

              <p>Clique no botão abaixo para instalar a extensão</p>

              <a
                href="https://www.bry.com.br/downloads/extension/firefox/assinatura_digital_bry.xpi"
                className="btn btn-lg btn-primary btn-extension-install"
              >
                Instalar Extensão!
              </a>
            </div>
          ) : (
            ""
          )}
          {browser === "edge" ? (
            <div className="isEdge">
              <h3 className="extension-message">
                Lamentamos, mas uma versão da extensão só estará disponível para
                o seu navegador na próxima atualização!
              </h3>
            </div>
          ) : (
            ""
          )}
          {browser === "opera" ? (
            <div className="isOpera">
              <h3 className="extension-message">
                Lamentamos, mas uma versão da extensão só estará disponível para
                o seu navegador na próxima atualização!
              </h3>
            </div>
          ) : (
            ""
          )}

          {browser === "safari" ? (
            <div className="isSafari">
              <h3>
                Detectamos que a Extensão para Assinatura Digital não está
                instalanda
              </h3>
              <input
                type="image"
                width="250"
                src={require("../../assets/imgs/baixar.png")}
                alt="Logo apple store"
              />
              <h4>
                Após a instalação é necessário habilitar a extensão nas
                preferências do Safari.
              </h4>
              <p>
                <b>1º Abra o aplicativo "BRy Assinatura Digital" instalado</b>
              </p>
              <p>
                <strong>
                  2º Dentro do aplicativo, clique em habilitar a extensão nas
                  preferências do Safari
                </strong>
              </p>
              <img
                src={require("../../assets/imgs/app.png")}
                alt="Print button extension"
              />
              <p>
                Caso a opção para habilitar a extensão não apareça nas
                preferências do Safari, encerre o navagador, e repita o passo 2.
              </p>
              <p>
                <strong>
                  4º Após ativar a extensão, basta recarregar a página.
                </strong>
              </p>
              <button onClick={document.location.reload(true)}>
                Recarregar página
              </button>
            </div>
          ) : (
            ""
          )}
          {browser === "iE" ? (
            <div className="isIE">
              <div className="extension-message">
                <h3>
                  Lamentamos, mas uma versão da extensão só estará disponível
                  para o seu navegador na próxima atualização!
                </h3>
              </div>
            </div>
          ) : (
            ""
          )}
          {browser === "unknown" ? (
            <div className="isUnknown">
              <h3 className="extension-message">
                Não foi possível identificar o seu browser!
              </h3>
            </div>
          ) : (
            ""
          )}
        </div>
)
}